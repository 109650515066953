import React from "react";

import ASASCBriefSummary from "./ASASCBriefSummary";
import SellingPoints from "./ASASCSellingPoints";

const title = "All Sri Lanka Asteroid Searching Campaign";
const description = "Astronomical Research Institute, Sri Lankais functioned under Celestia Group. We are the most qualified and most experienced Astronomical institute in Sri Lanka.<br/> We provide data analysis and research opportunities for our members and network, combining with leading international research institutes. We organize astronomical training programmes, night camps, workshops, stargazing events, public outreach programmes and any technical or enthusiastic sessions related to Astronomy. sponsorship and endorsement of international astronomical organizations. Our team is committed for the continuous improvement of Astronomy and Space science sectors in Sri Lanka by making young astronomers, required for the development of Science and technology fields in the Nation.";

const ASASCAbout = () => (
  <section id="about">
    <ASASCBriefSummary title={title} description="International Astronomical Search Collaboration (IASC) of Hardin-Simmons University at Texas - USA collaborating with Astronomical Research Institute, Sri Lanka(Local organizer), under the support of the National Aeronautics and Space Administration (NASA) organized 1st All-Sri Lanka Asteroid Search Campaign (ASASC) for the 1st time in Sri Lanka. It was held from 27th of May to 21st of June 2019 with the participation of 25 selected teams comprising 123 students, representing different schools, universities and science societies in all over the island." />
    {/*<SellingPoints />*/}
  </section>
);

export default ASASCAbout;
