import React from "react";
import { css } from "glamor";

import ContentWrapper from "../ContentWrapper";

import cubes1webp from "../../assets/img/icons/cubes-1.webp";
import cubes1png from "../../assets/img/icons/cubes-1.png";
import cetificateHolders from "../../assets/img/ASASC/certificate.jpeg";

import SellingPointsIcons from "../SellingPointsIcons.js";
import hackers from "../../assets/img/icons/hackersIcon.svg";
import schools from "../../assets/img/icons/schoolsIcon.svg";
import challenges from "../../assets/img/icons/challengesIcon.svg";
import applications from "../../assets/img/icons/applicationsIcon.svg";


const certificateHolders = "https://res.cloudinary.com/astronomerslk/image/upload/v1563023238/ASASC/lp0xivd9kuyxomsuhymq.jpg";

const caretCSS = (backgroundColor) =>
  css({
    backgroundColor,
    width: "26px",
    height: "4px"
  });

const imgStyle = {
  //maxWidth: "500px",
  width: "100%",
  textAlign: "center",
  height: "auto"

};

const blurbStyle = {
  margin: "32px 16px 32px 8px",
  fontSize: "16px",
  lineHeight: "24px",
  // maxWidth: "500px",
  textAlign: "left"
};
const flexChildStyle = {
  flex: 1,
  textAlign: "center",
  //Width: "300px",
  margin: "0 28px 32px 28px",
  maxWidth: "510px",
  "@media(max-width: 500px)": {
    zoom: "80%",
    margin: "16px 24px"
  },
  "@media(max-width: 350px)": {
    zoom: "75%",
    margin: "16px 24px"
  }
};

const ASASCBriefSummary = (props) => (
  <div
    css={{
      position: "relative",
      width: "100%",
      background: "#f8f8f8",
      paddingTop: "80px",
      paddingBottom: "80px"
    }}
  >
    <ContentWrapper>
      <div
        css={{
          background: "#ffffff",
          display: "grid",
          gridTemplateColumns: "5fr 3fr",
          padding: "80px 55px 80px 55px",
          margin: "0 auto 0 auto",
          borderRadius: "8px",
          boxShadow: "4px 9px 40px 2px rgba(7, 16, 75, 0.1)",
          border: "solid 1px #e8e8e8",
          "@media(max-width: 750px)": {
            padding: "32px 24px 32px 24px"
          },
          "@media(max-width: 1115px)": {
            display: "block"
          }
        }}
      >
        <div
          css={{
            paddingRight: "43px",
            "@media (max-width: 920px)": { paddingRight: "0px" }
          }}
        >
          <h1 css={{ paddingTop: "2px" }}>{props.title}</h1>
          <div
            css={{
              display: "grid",
              gridTemplateColumns: "auto auto 1fr",
              gridColumnGap: "4px",
              padding: "13px 0px 27px 0px"
            }}
          >
            <div {...caretCSS("#00205b")} />
            <div {...caretCSS("#c81c2e")} />
            <div {...caretCSS("#fedb01")} />
          </div>

          <p css={blurbStyle}>
            {props.description}
          </p>
          <div css={flexChildStyle}>

            {<SellingPointsIcons
              list={[
                {
                  icon: applications,
                  number: "180",
                  text: "Applications"
                },
                {
                  icon: schools,
                  number: "23",
                  text: "Schools/Teams"
                },
                {
                  icon: hackers,
                  number: "120",
                  text: "Researchers"
                }
              ]}
            />}
          </div>
        </div>
        <div
          css={{
            margin: "auto auto auto auto",
            "@media(max-width: 1115px)": {
              height: "50vw"
            },
            "@media(max-width: 715px)": {
              height: "55vw"
            }
          }}
        >
          <img
            css={imgStyle}
            src={certificateHolders}
            alt="Cetificate holders"
          />
          {/*
          <iframe
            title="2017-2018 Star Party Year"
            src="https://www.youtube.com/embed/z_P-rlnefMo?rel=0&amp;controls=0&amp;showinfo=0"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            css={{
              width: "500px",
              height: "281px",
              "@media(max-width: 1215px)": {
                width: "400px",
                height: "225px"
              },
              "@media(max-width: 1115px)": {
                paddingTop: "40px",
                width: "100%",
                height: "100%"
              }
            }}
          />*/}
        </div>
        <picture
          css={{
            width: "275px",
            height: "196px",
            "@media(max-width: 1000px)": {
              width: "165px",
              height: "118px",
              bottom: "-70px"
            },
            position: "absolute",
            bottom: "-140px",
            right: "40px"
          }}
        >
          <source srcSet={cubes1webp} type="image/webp" />
          <source srcSet={cubes1png} type="image/png" />
          <img
            css={{
              width: "275px",
              height: "196px",
              "@media(max-width: 1000px)": {
                width: "165px",
                height: "118px"
              }
            }}
            src={cubes1png}
            alt="Floating cubes"
          />
        </picture>
      </div>
    </ContentWrapper>
  </div>
);

export default ASASCBriefSummary;
